import $ from 'jquery';

import ApiConflictError from "JIX/errors/ApiConflictError.js";

import { createJobagent, updateJobagent } from 'JIX/jobagent/api.js';
import { constructFromForm } from 'JIX/jobagent/utils.js';

const stash = window.Stash['jobsearch/saved_search'];

function setup_saved_search_box() {
    const $form = $(stash.formSelector);

    if (window.Stash.common.userid) {
        $form.find('[type=submit]').removeClass('js-popup-agent');
        $form.on('submit', function(event) {
            event.preventDefault();

            const jobagent = constructFromForm($form[0]);

            // Trigger analytics event
            window.jixAnalytics('event', { category: 'Jobagent', action: 'add_jobagent', label: jobagent.search_params.q });

            const $success_box = $('#save_search_saved');

            const isNew = !jobagent.id;
            const saveFunction = isNew ? createJobagent : updateJobagent;

            saveFunction(jobagent)
                .then(() => {
                    $form.prop('hidden', true);
                    $success_box.prop('hidden', false);
                })
                .catch((error) => {
                    if (error instanceof ApiConflictError && error.error === 'duplicate_jobagent') {
                        location.href = error.extraData.redirect_url;
                    } else {
                        throw error;
                    }
                });
        });
    }
}

$(document).on('reloaded.jix.jobsearch', setup_saved_search_box);
setup_saved_search_box();
